import { BaseModel } from './base.model';
import { Vehicle } from './vehicle';

export class Segment extends BaseModel {
  id: number;
  categoryId: number;
  name: string;
  description: string;
  vehicles?: Array<Vehicle>;
  showBookings = false;
  included = true;
}
