import { BaseModel } from './base.model';
import { DizionariItem } from './dizionari-item';
import { NoleggioListino } from './noleggio-listino';
import { Segment } from './segment';

export class Vehicle extends BaseModel {
  id: number;
  segmentId: number;
  brand: string;
  model: string;
  version: string;
  carLicensePlate: string;
  poweredBy: DizionariItem = new DizionariItem();
  gearBox: DizionariItem = new DizionariItem();
  currentDayPrice: number;
  isElectric: boolean|null = null;
  included = true;

  static fromPraticaSegmento(
    pratica: any,
    segment: Segment,
    poweredByList: Array<DizionariItem>,
    gearBoxList: Array<DizionariItem>,
    currentPricesList: NoleggioListino
  ) {
    const vehicle = new Vehicle();
    vehicle.id = parseInt(pratica.numeroPratica, 10);
    vehicle.segmentId = segment.id;
    vehicle.brand = pratica.Marca;
    vehicle.model = pratica.Modello;
    vehicle.version = pratica.Versione;
    vehicle.carLicensePlate = pratica.Targa;

    // Powered by
    const poweredById = pratica.dettagli_tecnici[0].alimentazione_descrizione.idDIZIONARIO;
    vehicle.poweredBy = poweredByList.find((item: DizionariItem) => item.id === poweredById);

    // Powered by override (not default overrided GAS power)
    const notDefaultGas = pratica.dettagli_tecnici[0].AlimentazioneGAS;
    if (!!notDefaultGas) {
      vehicle.poweredBy = poweredByList.find((item: DizionariItem) => item.desc === notDefaultGas);
    }

    // Check if vehicle is electric
    vehicle.isElectric = (vehicle.poweredBy.id === -434 || vehicle.poweredBy.id === -6 || vehicle.poweredBy.id === -2);

    // gearBox
    const gearBox = !!pratica.dettagli_tecnici[0].cambio_extra_descrizione
                    ? pratica.dettagli_tecnici[0].cambio_extra_descrizione
                    : pratica.dettagli_tecnici[0].cambio_descrizione;
    vehicle.gearBox = gearBoxList.find(gb => gb.id === gearBox.idDIZIONARIO);

    // Current Price
    const costiVehicle = currentPricesList.costi.find((item: any) => (
          item.idSegmentoNoleggio === segment.id
      && item.idPeriodoNoleggio === 1 // Giorno
      && item.ckTypeAlimentazione === (vehicle.isElectric ? 1 : 0) // Elettrico o Classico
    ));

    if (!!costiVehicle === false && vehicle.isElectric) {
      currentPricesList.costi.find((item: any) => (
        item.idSegmentoNoleggio === segment.id
        && item.idPeriodoNoleggio === 1 // Giorno
        && item.ckTypeAlimentazione === 0 // Solo Elettrico
      ));
    }

    vehicle.currentDayPrice = !!costiVehicle === true ? costiVehicle.costo : null;

    return vehicle;
  }
}
