import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './services/auth.service';
import { NoleggioSegmentiService } from './services/noleggio-segmenti.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private noleggioSegmentiService: NoleggioSegmentiService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (!!params.token) {
        this.authService.storeToken(params.token);
      }

      if (!!params.instanceId) {
        this.authService.storeInstanceId(parseInt(params.instanceId, 10));
      }

      if (!!this.authService.getToken() && !!this.authService.getInstanceId()) {
        this.preloadData();
      }
    });

    this.preloadData();
  }

  async preloadData() {
    this.noleggioSegmentiService.list();

  }

}
