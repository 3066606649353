/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DizionariItem } from '../models/dizionari-item';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DizionariService {

  instanceId: number;

  inMemoryCache: any = {};

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.init();
  }

  async init() {
    this.instanceId = this.authService.getInstanceId();
  }

  async list(params: { tipologiadizionario?: string; group_by?: boolean } = {}): Promise<any> {
    return new Promise((resolve: CallableFunction) => {

      const cacheKey = `${this.instanceId}.${params.tipologiadizionario}.${params.group_by}`;

      if (!!this.inMemoryCache[cacheKey]) {
        return resolve(this.inMemoryCache[cacheKey]);
      }

      const endPoint = `${environment.apiBaseUrl}/${this.instanceId}/dizionari/list`;
      const token = this.authService.getToken();

      this.httpClient.get(endPoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          clear_cache: false,
          ...params
        }
      })
      .toPromise()
      .then(async (response: any) => {

        if (!!response === false || !!response.error) {
          console.log('DizionariService.list() - then() -> ', response);
          return resolve(null);
        }

        const data = response.data.map((item: any) => DizionariItem.fromObject(item, new DizionariItem()));
        this.inMemoryCache[cacheKey] = data;
        return resolve(data);
      })
      .catch((error: any) => {
        console.log('DizionariService.list() - catch() -> ', error);
        return resolve(null);
      });

    });
  }


}
