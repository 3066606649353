import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  tokenStorageKey = 'token';
  instanceIdStorageKey = 'instanceId';

  token: string;
  instanceId: number;

  constructor(
    private localStorageService: LocalStorageService
  ) {}

  storeToken(token: string) {
    this.token = token;
    this.localStorageService.setItem(this.tokenStorageKey, token);
  }

  getToken() {
    if (!!environment.devToken) { return environment.devToken; }

    if (!!this.token === false) {
      this.token = this.localStorageService.getItem(this.tokenStorageKey);
    }
    return this.token;
  }

  storeInstanceId(instanceId: number) {
    this.instanceId = instanceId;
    this.localStorageService.setItem(this.instanceIdStorageKey, instanceId);
  }

  getInstanceId() {
    if (!!this.instanceId === false) {
      this.instanceId = this.localStorageService.getItem(this.instanceIdStorageKey);
    }
    return this.instanceId;
  }

}
