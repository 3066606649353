/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NoleggioListino } from '../models/noleggio-listino';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoleggioListiniService {

  instanceId: number;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.init();
  }

  async init() {
    this.instanceId = this.authService.getInstanceId();
  }

  async list(): Promise<Array<NoleggioListino>> {
    return new Promise((resolve: CallableFunction) => {
      const endPoint = `${environment.apiBaseUrl}/noleggio-listini/list/${this.instanceId}`;
      const token = this.authService.getToken();

      this.httpClient.get(endPoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          clear_cache: false
        }
      })
      .toPromise()
      .then((response: any) => {
        if (!!response === false || !!response.error) {
          console.log('NoleggioListiniService.list() - then() -> ', response);
          return resolve(null);
        }

        const data = response.data.map((item: any) => NoleggioListino.fromObject(item, new NoleggioListino()));
        return resolve(data);
      })
      .catch((error: any) => {
        console.log('NoleggioListiniService.list() - catch() -> ', error);
        return resolve(null);
      });

    });
  }

  async current(): Promise<NoleggioListino> {
    return new Promise(async (resolve: CallableFunction) => {
      const now = new Date();
      const nowMonth = now.getMonth();
      const nowDate = now.getDate();

      const list: Array<NoleggioListino> = await this.list();
      let found: NoleggioListino|null|undefined = list.find((item: NoleggioListino) =>
        item.attivo
        && item.id !== 1
        && item.dataInizio.getMonth() <= nowMonth && item.dataInizio.getDate() <= nowDate
        && item.dataFine.getMonth() >= nowMonth && item.dataFine.getDate() >= nowDate);

      if (!!found === false) {
        found = list.find((item: NoleggioListino) => item.id === 1);
      }

      found.costi = found.costi.filter((item) => item.costo > 0);
      return resolve(found);
    });
  }

}
