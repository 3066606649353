import { BaseModel } from './base.model';
import { Segment } from './segment';

export class Category extends BaseModel {
  id: number;
  name: string;
  description?: string;
  segments?: Array<Segment>;
  included = true;
}
