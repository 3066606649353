/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { BaseModel } from './base.model';
import { NoleggioListinoCosto } from './noleggio-listino-costo';

export class NoleggioListino extends BaseModel {

  id: number;
  attivo: boolean;
  NomeListino: string;
  costi: Array<any>;

  private _dataInizio: Date|null = null;
  public get dataInizio(): Date {
    return this._dataInizio;
  }
  public set dataInizio(date: Date|string) {
    this._dataInizio = typeof date === 'string' ? new Date(date) : date;
  }

  private _dataFine: Date|null = null;
  public get dataFine(): Date {
    return this._dataFine;
  }
  public set dataFine(date: Date|string) {
    this._dataFine = typeof date === 'string' ? new Date(date) : date;
  }

  static fromObject(sourceObject: any, instance: NoleggioListino): NoleggioListino {
    instance.id = sourceObject.id;
    instance.attivo = sourceObject.attivo === '1';
    instance.NomeListino = sourceObject.NomeListino;
    instance.dataInizio = sourceObject.dataInizio;
    instance.dataFine = sourceObject.dataFine;
    instance.costi = sourceObject.costi.map(
      (item: NoleggioListinoCosto) => NoleggioListinoCosto.fromObject(item, new NoleggioListinoCosto())
    );
    return instance;
  }

}
