/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { BaseModel } from './base.model';


export class NoleggioListinoCosto extends BaseModel {

  id: number;

  private _idListinoNoleggio: number;
  public get idListinoNoleggio(): number {
    return this._idListinoNoleggio;
  }
  public set idListinoNoleggio(value: string|number) {
    this._idListinoNoleggio = typeof value === 'string' ? parseInt(value, 10) : value;
  }

  private _idPeriodoNoleggio: number;
  public get idPeriodoNoleggio(): number {
    return this._idPeriodoNoleggio;
  }
  public set idPeriodoNoleggio(value: string|number) {
    this._idPeriodoNoleggio = typeof value === 'string' ? parseInt(value, 10) : value;
  }

  private _idSegmentoNoleggio: number;
  public get idSegmentoNoleggio(): number {
    return this._idSegmentoNoleggio;
  }
  public set idSegmentoNoleggio(value: string|number) {
    this._idSegmentoNoleggio = typeof value === 'string' ? parseInt(value, 10) : value;
  }

  private _ckTypeAlimentazione: number;
  public get ckTypeAlimentazione(): number {
    return this._ckTypeAlimentazione;
  }
  public set ckTypeAlimentazione(value: string|number) {
    this._ckTypeAlimentazione = typeof value === 'string' ? parseInt(value, 10) : value;
  }

  private _costo: number;
  public get costo(): number {
    return this._costo;
  }
  public set costo(value: string|number) {
    this._costo = typeof value === 'string' ? parseFloat(value) : value;
  }

  static fromObject(sourceObject: any, instance: NoleggioListinoCosto): NoleggioListinoCosto {
    instance.id = sourceObject.id;
    instance.idListinoNoleggio = sourceObject.idListinoNoleggio;
    instance.idPeriodoNoleggio = sourceObject.idPeriodoNoleggio;
    instance.idSegmentoNoleggio = sourceObject.idSegmentoNoleggio;
    instance.ckTypeAlimentazione = sourceObject.ckTypeAlimentazione;
    instance.costo = sourceObject.costo;
    return instance;
  }

}

