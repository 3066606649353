/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/category';
import { Segment } from '../models/segment';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Vehicle } from '../models/vehicle';
import { NoleggioListiniService } from './noleggio-listini.service';
import { DizionariItem } from '../models/dizionari-item';
import { DizionariService } from './dizionari.service';
import { NoleggioListino } from '../models/noleggio-listino';

@Injectable({
  providedIn: 'root'
})
export class NoleggioSegmentiService {

  instanceId: number;
  poweredByList: Array<DizionariItem|null> = null;
  gearBoxList: Array<DizionariItem|null> = null;
  currentPricesList: NoleggioListino|null = null;

  inMemoryList: Array<Category>|null;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private noleggioListiniService: NoleggioListiniService,
    private dizionariService: DizionariService
  ) {
    this.init();
  }

  async init(): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!!this.instanceId) {
        return resolve(true);
      }

      if (!!this.poweredByList === false) {
        this.poweredByList = await this.dizionariService.list({ tipologiadizionario: 'AL,AL_P', group_by: false });
      }

      if (!!this.gearBoxList === false) {
        this.gearBoxList = await this.dizionariService.list({ tipologiadizionario: 'CA,CA_P', group_by: false });
      }

      this.instanceId = this.authService.getInstanceId();

      return resolve(true);
    });
  }

  async getCurrentPricesList() {
    if (!!this.currentPricesList === false) {
      this.currentPricesList = await this.noleggioListiniService.current();
    }
    return this.currentPricesList;
  }

  async list(): Promise<Array<Segment>|null> {
    await this.init();

    const currentPricesList = await this.getCurrentPricesList();
    return new Promise((resolve: CallableFunction) => {
      const endPoint = `${environment.apiBaseUrl}/noleggio-segmenti/list/${this.instanceId}`;
      const token = this.authService.getToken();

      if (!!this.inMemoryList) {
        console.log('[NOTICE: NoleggioSegmentiService.inMemoryList used]');
        return resolve(this.inMemoryList);
      }

      this.httpClient.get(endPoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          clear_cache: true,
          with_categoria_descrizione: true,
          with_pratiche: true,
          with_noleggio_listini_costi: true,
          // with_noleggio_listini_costi_and_listini: true
        }
      })
      .toPromise()
      .then(async (response: any) => {

        if (!!response === false || !!response.error) {
          console.log('NoleggioSegmentiService.list() - then() -> ', response);
          return resolve(null);
        }

        const data = await this.mapResponseDataListToModels(response.data, currentPricesList);
        this.inMemoryList = data;
        return resolve(data);
      })
      .catch((error: any) => {
        console.log('NoleggioSegmentiService.list() - catch() -> ', error);
        return resolve(null);
      });
    });
  }

  async getVehicle(id: number): Promise<Vehicle| null> {
    const list = await this.list();
    let vehicle: Vehicle = null;

    for (const segment of list) {
      if (!!segment.vehicles === false) { continue; }
      vehicle = segment.vehicles.find(v => v.id === id);
      if (!!vehicle) { break; }
    }

    return vehicle;
  }

  mapResponseDataListToModels(list: Array<any>, currentPricesList: any): Array<Category> {
    const categories = new Array<Category>();
    for (const segmentItem of list) {

      // 1A. Get category
      let category = categories.find(c => c.id === parseInt(segmentItem.categoria_descrizione.idDIZIONARIO, 10));

      // 1B. Create and push category if not exists
      if (!!category === false) {
        category = new Category();
        category.id = segmentItem.categoria_descrizione.idDIZIONARIO; //parseInt(segmentItem.categoria_descrizione.idDIZIONARIO, 10);
        category.name = segmentItem.categoria_descrizione.DescrizioneDizionario;
        category.description = segmentItem.categoria_descrizione.DescrizioneDizionario;
        category.segments = new Array<Segment>();
        categories.push(category);
      }

      // 2A. Get segment
      let segment = category.segments.find(s => s.id === segmentItem.idSegmento/*parseInt(segmentItem.idSegmento, 10)*/);

      // 2B. Create and push segment if not exists
      if (!!segment === false) {
        segment = new Segment();
        segment.id = segmentItem.idSegmento; //parseInt(segmentItem.idSegmento, 10);
        segment.categoryId = category.id;
        segment.name = segmentItem.NomeSegmento;
        segment.description = segmentItem.Descrizione;
        segment.vehicles = new Array<Vehicle>();
        category.segments.push(segment);
      }

      // 3A. Get vehicles
      for (const pratica of segmentItem.pratiche) {
        let vehicle = segment.vehicles.find(v => v.id === pratica.numeroPratica/*parseInt(pratica.numeroPratica, 10)*/);

        // 3B. Create and push vehicle if not exists
        if (!!vehicle === false) {
          vehicle = Vehicle.fromPraticaSegmento(pratica, segment, this.poweredByList, this.gearBoxList, currentPricesList);
          segment.vehicles.push(vehicle);
        }
      }
    }

    return categories;
  }

}
