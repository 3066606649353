import { BaseModel } from './base.model';

export class DizionariItem extends BaseModel {

  id: number;
  parentId: number|null;
  type: string;
  desc: string;
  descAlt1: string;
  descAlt2: string;
  descAlt3: string;

  static fromObject(sourceObject: any, instance: any) {
    instance.id = parseInt(sourceObject.idDizionario, 10);
    instance.parentId = !!sourceObject.idTipologiaClasse ? parseInt(sourceObject.idTipologiaClasse, 10) : null;
    instance.type = sourceObject.tipologiadizionario;
    instance.desc = sourceObject.DescrizioneDizionario;
    instance.descAlt1 = sourceObject.DescrizioneAlternativa1;
    instance.descAlt2 = sourceObject.DescrizioneAlternativa2;
    instance.descAlt3 = sourceObject.DescrizioneAlternativa3;
    return instance;
  }

}
